/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './heroSection.css';
import junkeeToilet from './images/junkee-toilet.png';
import junkeeHeatmap2 from './images/junkee-heatmap-2.png'; // Adjust the path as needed

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const HeroSection = (props) => {
  const heroTextRef = useRef(null);
  const rightImageRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      heroTextRef.current,
      {
        opacity: .5, // Start fully transparent
        scale: .5, // Start at half size
      },
      {
        opacity: 1, // Fade in to fully visible
        scale: 1, // Scale up to full size
        duration: .5, // Animation duration for the fade and scale
        scrollTrigger: {
          trigger: heroTextRef.current, // Element to trigger the animation
          toggleActions: "restart none none none",
        },
      }
    );

// Animate the right image
gsap.fromTo(
    rightImageRef.current,
    {
      rotation: 0, // Start back to the original position
      x: 100,
      y: 100,
    },
    {
      rotation: -30, // Tilt to the left (30 degrees)
      x: -100, // Adjust x position if needed
      y: 0, // Adjust y position if needed
      scrollTrigger: {
        trigger: rightImageRef.current, // Element to trigger the animation
        start: "top 90%", // Start the animation when the top of the image is at 10% of the viewport height
        end: "top 40%", // End the animation when the top of the image is at 40% of the viewport height
        scrub: true, // Smooth scrubbing
      },
    }
  );
  }, []);

  return (
    <div style={{position: 'relative'}}>
      <div ref={heroTextRef} style={{ top: 0, right: -400}}>
        <div className='row align-items-center justify-content-around mt-3'>
          <div className='col-md-8 col-12 text-center' style={{}}>
            {props.children}
          </div>
        </div>
      </div>

      {
        props.show &&
      <div className="right-image-container" style={{ position: "absolute", height: "100vh", overflow: "hidden", top: -100, right: -400 }}>
        <img
          ref={rightImageRef}
          src={junkeeHeatmap2}
          alt="Right Image"
          className="peeking-image right-image"
        />
      </div>
      }
    </div>
  );
};

export default HeroSection;
