/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const BuyPumpFun = () => {
  return (
<div className="tg-buy svelte-vrm4ox">
  <h2 className="svelte-vrm4ox">Buy on PUMP.FUN</h2>
  <div className="steps svelte-vrm4ox">
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 1</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">
          Install the <a href="https://phantom.app/" target="_blank" className="svelte-vrm4ox">Phantom Wallet</a>
        </p>
        <div id="botLinks" className="svelte-vrm4ox">
          <a href="https://phantom.app/" target="_blank" className="svelte-vrm4ox">
            <img src="https://pumpsites.fun/_app/immutable/assets/phantom.D6TMLVAj.jpg" alt="Phantom" className="svelte-vrm4ox" />
          </a>
        </div>
      </div>
    </div>
    
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 2</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">
          Get SOL from a crypto exchange or bridge and send it to your Phantom wallet.
        </p>
      </div>
    </div>
    
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 3</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">
          Copy the official token address above.
        </p>
      </div>
    </div>
  </div>

  <div className="steps svelte-vrm4ox">
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 4</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">Go to pump.fun</p>
        <div id="botLinks" className="svelte-vrm4ox">
          <a href="" target="_blank" className="svelte-vrm4ox">
            <img src="https://pumpsites.fun/_app/immutable/assets/pump.6ShSNghm.jpg" alt="pumpfun" className="svelte-vrm4ox" />
          </a>
        </div>
      </div>
    </div>
    
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 5</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">
          Connect your Phantom wallet and paste the address in the search.
        </p>
      </div>
    </div>
    
    <div className="step-container svelte-vrm4ox">
      <div className="stepHeader svelte-vrm4ox">
        <h3 className="svelte-vrm4ox">Step 6</h3>
      </div>
      <div className="step svelte-vrm4ox">
        <p className="svelte-vrm4ox">
          Enter the amount, confirm the transaction in your wallet and enjoy your PWANGU!
        </p>
      </div>
    </div>
  </div>
</div>
)
}

export default BuyPumpFun;
