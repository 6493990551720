import React from "react";
import "./circleText.css"; // Import the CSS file for styles

const CircleText = ({ text }) => {
  const radius = 10; // Adjust the radius based on your design
  const characters = text.split(""); // Split the text into individual characters
  const angleStep = 360 / characters.length; // Calculate the angle step per character

  return (
    <div
      id="circletext"
      style={{
        transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        transformStyle: "preserve-3d",
        willChange: "transform",
        position: "absolute",
        height: `${radius * 2}em`,
        width: "100%",
        top: -40,
        left: 0,
        animation: "spin 8s linear infinite", // Apply the spinning animation
      }}
      className="circle-text text-white"
    >
      <div aria-label={text} style={{ position: "relative", height: `${radius * 2}em` }}>
        {characters.map((char, index) => {
          const rotateAngle = index * angleStep; // Calculate the rotation angle for each character
          return (
            <span
              key={index}
              style={{
                position: "absolute",
                bottom: "auto",
                left: "50%",
                transform: `translateX(-0.275em) rotate(${rotateAngle}deg)`,
                transformOrigin: `center ${radius}em`,
              }}
            >
              {char === " " ? "\u00A0" : char} {/* Convert spaces to non-breaking spaces */}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default CircleText;
