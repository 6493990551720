/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import junkeeToilet from './images/junkee-toilet.png';
import SocialLinksAccordion from './accordion.js';
import mentalityIcon from './images/logo.png';
import junkeeCoin from './images/junkeeCoin.PNG'; // Adjust the path as needed
import junkeeBlack from './images/junkee-black.PNG'; // Adjust the path as needed
import junkeeHeatmap2 from './images/junkee-heatmap-2.png'; // Adjust the path as needed
import starsBackground from './images/stars-bg.jpg'
import CircleText from './circleText.js';
import { ScrollTrigger } from 'gsap/all';
import HeroSection from './heroSection.js';
import BuyPumpFun from './buyPumpfun.js';
gsap.registerPlugin(ScrollTrigger)

function App() {
  const headingRef = useRef(null);
  const contentRef = useRef(null);
  const leftImageRef = useRef(null)
  const [showContent, setShowContent] = useState(true);
  const buySectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Assume mobile if width is less than 768px

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const introText = (
    <h1
      ref={headingRef}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[10vw] font-bold russo-one-regular select-none"
    >
      $JUNKEE
    </h1>
  );

  const scrollToBuySection = () => {
    buySectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const textToCopy = 'EJ6r55VaTxKwaPTBWU6naLsUoSnBJ59Q3jZtE5wrpump';

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Contract Address copied to clipboard');
        alert('Contract Address copied to clipboard!'); // Optional: Provide user feedback
      })
      .catch(err => {
        console.error('Error copying text: ', err);
      });
  };

  const Header = (
    <>
      <div className='w-100 fixed-top p-3 bg-black row align-items-center' style={{ background: '#0A0A2A' }}>
        {/* {isMobile ? (
          <img src={mentalityIcon} alt="Mentality Icon" className="icon" style={{ width: '40px', height: 'auto' }} />
        ) : ( */}
          <p className=' select-none col-3'>{`$JUNKEE`}</p>
        {/* )} */}
        
        {isMobile ? (
          <SocialLinksAccordion />
        ) : (
          <div className='col-6 d-flex justify-content-center gap-10 row'>
            <>
              <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
                <i className="bi bi-bar-chart icon"></i>
                <p className=' mb-0'>Chart</p>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
                <i className="bi bi-telegram icon"></i>
                <p className=' mb-0'>Telegram</p>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
                <i className="bi bi-twitter icon"></i>
                <p className=' mb-0'>Twitter</p>
              </a>
              <a href="https://junkee-memes.fun" target="_blank" rel="noopener noreferrer" className="link-style">
                <i className="bi bi-bar-chart icon"></i>
                <p className=' mb-0 text-gold'>Meme Maker</p>
              </a>
            </>
          </div>
        )}
  
        <div className='col-2'>
          <button
            className='btn btn-gold'
            type='button'
            onClick={scrollToBuySection}
          >
            BUY $JUNKEE
          </button>
        </div>
      </div>
    </>
  );
  

  const footer = (
    <div className='w-100 p-3 row justify-content-around' style={{ backgroundColor: '#0A0A2A' }}>
      {/* {isMobile ? (
        <img 
          src={mentalityIcon} 
          alt="Mentality Icon" 
          className="icon" 
          style={{ width: '40px', height: 'auto', maxWidth: '100%', objectFit: 'contain' }} 
        />
      ) : ( */}
        <p className=' col-6 select-none'>{`$JUNKEE`}</p>
      {/* )} */}
  
      <div className='col-6 d-flex justify-content-center gap-10 row'>
        {isMobile ? (
          <SocialLinksAccordion />
        ) : (
          <>
            <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
              <i className="bi bi-bar-chart icon"></i>
              <p className=' mb-0'>Chart</p>
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
              <i className="bi bi-telegram icon"></i>
              <p className=' mb-0'>Telegram</p>
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="link-style">
              <i className="bi bi-twitter icon"></i>
              <p className=' mb-0'>Twitter</p>
            </a>
          </>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const runAnimation = () => {
      const tl = gsap.timeline();
  
      // Intro Text Fade In
      tl.fromTo(
        headingRef.current,
        { opacity: 0, y: 100 },
        { 
          duration: 1.5, 
          opacity: 1, 
          y: 0,
          ease: "power4.out", 
          color: 'white' 
        }
      )
      .to(headingRef.current, { 
        duration: 1.5, 
        color: '#FFAA00', 
        ease: "power4.out" 
      })
      .to(headingRef.current, {
        duration: 1,
        color: 'black',
        opacity: 0,
        ease: "power4.out"
      })
      .set(headingRef.current, { display: 'none' })
      .call(() => {
        setShowContent(true); // Show content after intro is done
      });
    };
  
    window.addEventListener('load', runAnimation());
  
    return () => {
      window.removeEventListener('load', runAnimation()); // Clean up event listener
    };
  }, []);

      // Fade-in effect for content
      const fadeInContent = () => {
        if (contentRef.current) {
          gsap.fromTo(
            contentRef.current,
            { opacity: 0 }, // Start with hidden content
            { opacity: 1, duration: 2, ease: "power4.out" } // Fade in content
          );
        }
      };

      useEffect(() => {
        gsap.fromTo(
          leftImageRef.current,
          {
            opacity: .5, // Start fully transparent
            scale: 1, // Start at half size
            x: -100
          },
          {
            x: 50,
            opacity: 1, // Fade in to fully visible
            scale: 1, // Scale up to full size
            duration: .5, // Animation duration for the fade and scale
            scrollTrigger: {
              trigger: leftImageRef.current, // Element to trigger the animation
              toggleActions: "restart none none none",
            },
          }
        )}, []);

  
  // Effect for showing content with fade-in animation
  useEffect(() => {
    if (showContent) {
      fadeInContent();
    }
  }, [showContent]);
  const HeroImage = () => {
    return (
      <>
        <div id="heroImage" className="svelte-vrm4ox">
            <div id="heroImageBg" className="svelte-vrm4ox"></div>
            <div id="spinningCoin" className="svelte-vrm4ox">
                <div className="coin-wrapper svelte-9u00oj">
                <div
                        className="coin svelte-9u00oj"
                        style={{
                          '--img-url-property': `url(${junkeeCoin})`, // Use imported image
                          '--lighter-color-property': 'rgb(57, 255, 20)', // Neon Green
                          '--darker-color-property': 'rgb(0, 139, 139)', // Dark Cyan
                          '--average-color-property': 'rgb(255, 215, 0)', // Gold
                        }}
                    >
                        <div className="side heads svelte-9u00oj">
                            <div className="imageCoin svelte-9u00oj"></div>
                        </div>
                        <div className="side tails svelte-9u00oj">
                            <div className="imageCoin svelte-9u00oj"></div>
                        </div>
                    </div>
                      <CircleText text={'⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐'}/>
                </div>
                <h1 className='text-6xl' style={{marginTop: '60px'}}>$JUNKEE</h1>
            </div>
        </div>
      </>
    );
};

const LineAcross = () => {

  return (
    <>
    <div className="marquee-wrapper svelte-vrm4ox" style={{overflow: 'hidden', marginTop: '5vh'}}>
      <div className="slanted-marquee-wrapper svelte-vrm4ox">
        <div className="slanted-bar behind svelte-vrm4ox"></div>
        <div className="marquee-container svelte-vrm4ox">
          <div className="marquee svelte-vrm4ox">
            {tokens.map((token, index) => (
              <span key={index} className="svelte-vrm4ox" style={{color: 'yellow'}}>
                {token} 
                <img 
                  id="marqueeImage" 
                  src={junkeeBlack} 
                  alt="Token Logo" 
                  className="svelte-vrm4ox" 
                />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

const BuyOnTelegram = () => {
  return (
    <div className="tg-buy svelte-vrm4ox" style={{ marginBottom: '5em' }}>
      <h2 className="svelte-vrm4ox">Buy on TELEGRAM</h2>
      <div className="steps svelte-vrm4ox">
        {/* Step 1 */}
        <div className="step-container svelte-vrm4ox">
          <div className="stepHeader svelte-vrm4ox">
            <h2 className="svelte-vrm4ox">Step 1</h2>
          </div>
          <div className="step svelte-vrm4ox">
            <p className="svelte-vrm4ox">Select a TG bot</p>
            <div id="botLinks" className="svelte-vrm4ox">
              <a
                href=" "
                target="_blank"
                rel="noopener noreferrer"
                className="svelte-vrm4ox"
              >
                <img
                  src="https://pumpsites.fun/_app/immutable/assets/unibot.WTOuFnjk.jpg"
                  alt="UniBot"
                  className="svelte-vrm4ox"
                />
              </a>
              <a
                href=" "
                target="_blank"
                rel="noopener noreferrer"
                className="svelte-vrm4ox"
              >
                <img
                  src="https://pumpsites.fun/_app/immutable/assets/bonkbot.Ci1ptc5X.jpg"
                  alt="BonkBot"
                  className="svelte-vrm4ox"
                />
              </a>
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
                className="svelte-vrm4ox"
              >
                <img
                  src="https://pumpsites.fun/_app/immutable/assets/stbot.FFNH93Ek.jpg"
                  alt="BullX Bot"
                  className="svelte-vrm4ox"
                />
              </a>
            </div>
          </div>
        </div>
        
        {/* Step 2 */}
        <div className="step-container svelte-vrm4ox">
          <div className="stepHeader svelte-vrm4ox">
            <h2 className="svelte-vrm4ox">Step 2</h2>
          </div>
          <div className="step svelte-vrm4ox">
            <p className="svelte-vrm4ox">
              Follow the instructions in the bot to create a wallet and fund it with SOL.
            </p>
          </div>
        </div>
        
        {/* Step 3 */}
        <div className="step-container svelte-vrm4ox">
          <div className="stepHeader svelte-vrm4ox">
            <h2 className="svelte-vrm4ox">Step 3</h2>
          </div>
          <div className="step svelte-vrm4ox">
            <p className="svelte-vrm4ox">
              Paste the contract address, input the amount of SOL to buy with, and click buy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const tokens = Array(10).fill('$JUNKEE'); // Example array of tokens

const gradientStyle = {
  background: 'linear-gradient(135deg, rgba(0, 139, 139, 0.8) 0%, rgba(57, 255, 20, 0.6) 50%, rgba(255, 215, 0, 0.8) 100%)',
  minHeight: '100vh', // Ensures the gradient covers the full height of the viewport
};


  return (
    <div 
    style={{ 
      backgroundImage: `url(${starsBackground})`, 
      backgroundSize: 'cover',   // or 'contain' depending on the desired effect
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%'              // Optional width
    }}    
    >
      {/* {introText} */}
      {showContent && (
        <div className='check' style={{overflowX: 'hidden'}}>
          <div ref={contentRef}  className='py-3 mt-10'>
            {Header}
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center" style={{marginTop: '15vh'}}>
                <HeroImage /> 
              </div>
            </div>
          <LineAcross />
          <div>
        </div>

        <div className='h-1/2' style={{marginTop: '500px'}}>
          <HeroSection show>
          <h1 className='text-6xl text-outline' style={{color: 'green orange'}}>
                🌌🚀 Join Junkee to Pump It to Space! 🚀🌌
              </h1>
              <p className='text-4xl mt-5' style={{ color: 'orange'}}>
                Are you ready to launch your crypto journey? Join Junkee and be part of an incredible mission to pump our coin to the moon! Let’s make history—don’t miss out on this stellar opportunity! 🌕✨
              </p>
          </HeroSection>
        </div>

        <div className='h-1/2 col-12 row justify-content-around' style={{marginTop: '500px'}}>
        
          <div className='col-md-4 col-12' ref={leftImageRef}>
            <img
            alt='joj'
            src={junkeeToilet}
            style={{ position: 'relative', top: '-50px' }}
            />
          </div>
          <div className='col-md-8 col-12'>
              <HeroSection>
              <h1 className='text-6xl' style={{color: 'yellow'}}>
              ⚠️ But Wait! Junkee needs to get back home first! 🌌 Can you help him launch his ship? 🚀 <br />Give Junkee a boost by joining the mission ⚠️
                  </h1>
              </HeroSection>
          </div>


        </div>

        <div className='' style={{marginTop: '300px'}}>
        <h1 className='text-6xl text-center'>
            🚀🌕 Fuel Junkee's Journey to the Moon! 🌕🚀
          </h1> 
         <div className='row align-items-center h-75 mt-3'> 
            <div className='col-md-12 col-12 text-center'   
           >
              <p className='text-4xl '>
                <h1>TOKENOMICS</h1>
              </p>
              <div className='text-2xl'>
              <div className="info-card svelte-vrm4ox justify-content-around col-6">
          <div className="info-section total-supply mx-0 svelte-vrm4ox" style={{color: 'black'}}>
            <h2 className="svelte-vrm4ox">Total supply</h2>
            <h2 className="highlight svelte-vrm4ox">
              <span className="svelte-vrm4ox">1B</span>
            </h2>
          </div>
          <div className="info-section svelte-vrm4ox">
            <p className="svelte-vrm4ox">
              Blockchain: <span className="svelte-vrm4ox">Solana</span>
            </p>
            <p className="svelte-vrm4ox">
              Token standard: <span className="svelte-vrm4ox">SPL</span>
            </p>
            <p className="svelte-vrm4ox">
              Decimals: <span className="svelte-vrm4ox">6</span>
            </p>
          </div>
          <div className="svelte-vrm4ox">
            <div className="market-cap svelte-vrm4ox">
              <div className="background-blur-small svelte-vrm4ox"></div>
              <div className="live-status svelte-vrm4ox">
                <h2 className="svelte-vrm4ox">LIVE ON PUMP.FUN</h2>
                <span className="live-dot svelte-vrm4ox"></span>
              </div>
              <button className="pumpfun svelte-vrm4ox">
                <a
                  href=" "
                  target="_blank"
                  rel="noopener noreferrer"
                  className="svelte-vrm4ox"
                >
                  <img
                    src="https://pumpsites.fun/_app/immutable/assets/pumpfun.F2oKOYTM.png"
                    alt="pumpfun"
                    className="svelte-vrm4ox"
                    style={{ height: '20px', marginBottom: '4px', marginRight: '10px', display: 'inline' }}
                  />
                  VIEW TOKEN
                </a>
              </button>
            </div>
          </div>
        </div>
      <div id="keyInfo" className="svelte-vrm4ox text-white"><div className="infoItem svelte-vrm4ox text-white"><h2 className="svelte-vrm4ox text-white">LP Burned</h2> <p className="svelte-vrm4ox text-white">100% of the liquidity<br />tokens are burned.</p></div> <div className="infoItem svelte-vrm4ox text-white"><h2 className="svelte-vrm4ox text-white">Zero Tax</h2> <p className="svelte-vrm4ox text-white">Buy, sell and transfer<br />tokens with no tax.</p></div> <div className="infoItem svelte-vrm4ox text-white"><h2 className="svelte-vrm4ox text-white">Revoked</h2> <p className="svelte-vrm4ox text-white">Mint and freeze authorities<br />have been revoked.</p></div></div>
        </div>
      </div>
  </div>


<br></br>

      </div>
          <div ref={buySectionRef} className='row align-items-center h-screen'> {/* Added ref for scrolling */}
                <div className='col-12 text-center' style={{padding: '0 90px'}}>
                  <p className='text-6xl '>
                    <h1>HOW TO BUY</h1>
                  </p>
                  <BuyOnTelegram />
                  <div style={{marginTop: '100px'}}>
                    <BuyPumpFun />
          
                  </div>
                </div>
              </div>
        </div>
      </div>
      
      )}
                
      <footer>
        {footer}
      </footer>
    </div>
  );
}

export default App;
